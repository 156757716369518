import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'

const axiosIns = axios.create({})

axios.interceptors.request.use(
    config => {
        const jwt = localStorage.getItem('accessToken')
        if (jwt !== '') config.headers.common.Authorization = `Bearer ${jwt}`

        return config
    },
    error => Promise.reject(error)
)

axiosIns.interceptors.response.use(function(response) {
    return response
}, async function(error) {
    const router = (await import('@/router')).default

    if (error.response.status === 403) {
        if (error.response.data === 'user no client') {
            await store.dispatch('user/setUserNoClients', false)
        } else if (error.response.data === 'user missing client') {
           await store.dispatch('user/selectFirstClient')
        }
    }

    if (router.app) {
        if (error.response.status === 401) {
            const currentRoute = router.app.$route.name

            if (router.options && router.options.routes) {
                const routeInstance = router.options.routes.find(ele => ele.name === currentRoute)

                if (routeInstance && (!routeInstance.meta || routeInstance.meta.requiresAuth === true)) {
                    router.push({name: 'login'})
                }
            }

        }
    }

    return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
